import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

const RosterTable = ({ roster }) => {
    const [open, setOpen] = useState(false);
    const [bedDetails, setBedDetails] = useState({ nurse: "", beds: [], shift: "" });

    const handleCellClick = (nurse, beds, shift) => {
        setBedDetails({ nurse, beds, shift });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setBedDetails({ nurse: "", beds: [], shift: "" });
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nurse Name</TableCell>
                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                            <TableCell key={day}>{day}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {roster.map((nurse) => (
                        <TableRow key={nurse.name}>
                            <TableCell>{nurse.name}</TableCell>
                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                <TableCell key={day}>
                                    {nurse.schedule[day].map((shiftDetail, index) => (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            onClick={() =>
                                                handleCellClick(nurse.name, shiftDetail.beds, shiftDetail.shift)
                                            }
                                        >
                                            {shiftDetail.shift}
                                        </Button>
                                    ))}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Assigned Beds</DialogTitle>
                <DialogContent>
                    <p>
                        <strong>Nurse:</strong> {bedDetails.nurse}
                    </p>
                    <p>
                        <strong>Shift:</strong> {bedDetails.shift}
                    </p>
                    <p>
                        <strong>Beds:</strong> {bedDetails.beds.join(", ")}
                    </p>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RosterTable;
