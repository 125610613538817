import React, { useState, useEffect } from "react";
import "./nurseroster.css"; // External CSS file
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import { Today, ViewWeek, ViewModule } from "@mui/icons-material";
import AddNurseHoliday from './AddNurseHoliday'
const NurseHolidayPlanner = () => {
  const [addShow, setAddShow] = useState(false)
  const [nurses, setNurses] = useState([]);
  const [holidays, setHolidays] = useState([])
  const [minNoNurses, setMinNoNurses] = useState(0); // For the min_no_nurses from API
  const [startDate, setStartDate] = useState(""); // Added state for start date
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const alert = useAlert();
  const [isWeeklyView, setIsWeeklyView] = useState(true);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    const v2 = tokenString?.replace(/["]+/g, ""); // Ensure `tokenString` is handled safely

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + v2,
    };

    const fetchNurses = axios.get(`${APIURL}/api/v1/service-provider/nurse/`, { headers });
    const fetchRosterDetails = axios.get(`${APIURL}/api/v1/service-provider/roster-predetails/`, { headers });
    const fetchCreatedHolidays = axios.get(`${APIURL}/api/v1/service-provider/nurse-holidays/`, { headers });
    Promise.all([fetchNurses, fetchRosterDetails, fetchCreatedHolidays])
      .then(([nursesRes, detailsRes, holidaysRes]) => {
        if (nursesRes.status === 200) {
          setNurses(nursesRes.data);
        }
        if (detailsRes.status === 200) {
          setMinNoNurses(2); // Update min_no_nurses
        }
        if (holidaysRes.status === 200) {
          setHolidays(holidaysRes.data.details.slice(0, 4));
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const getDateRange = () => {
    if (!startDate) return "";
    const start = new Date(holidays[0].from_date);
    const end = new Date(holidays[0].from_date);
    end.setDate(start.getDate() + 27); 
    return `${start.toISOString().split("T")[0]} - ${end.toISOString().split("T")[0]}`;
  };

  return (
    <div className="app">

      <div className="schedule">
        <div className="roster-head">
          <label>Existing Schedules</label>
          <button onClick={() => { setAddShow(true) }}>Add Schedule</button>
        </div>


        <div className="view-toggle" style={{ display: 'flex' }}>
          <button onClick={() => setIsWeeklyView(true)} style={{ backgroundColor: isWeeklyView && '#2456AE', color: isWeeklyView && '#fff' }}>
            <ViewWeek /> Weekly View
          </button>
          <button onClick={() => setIsWeeklyView(false)} style={{ backgroundColor: !isWeeklyView && '#2456AE', color: !isWeeklyView && '#fff' }}>
            <ViewModule /> Monthly View
          </button>
        </div>
        <p className="date-range">
          {getDateRange() && <strong>Date Range: {getDateRange()}</strong>}
        </p>
        <table>
          <thead>
            <tr>
              <th>Nurse</th>
              {isWeeklyView
                ? daysOfWeek.map((day) => <th key={day}>{day}</th>)
                : Array.from({ length: 28 }, (_, i) => (
                  <th key={i}>Day {i + 1}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {holidays.length > 0 ? (
              holidays.map((schedule) => {
                // Generate the schedule
                const weeklySchedule = daysOfWeek.map((day) =>
                  schedule.off_days.includes(day) ? "Off" : "Work"
                );

                // Generate monthly schedule by repeating weekly schedule
                const monthlySchedule = Array.from({ length: 28 }, (_, i) =>
                  weeklySchedule[i % 7]
                );

                const scheduleToRender = isWeeklyView
                  ? weeklySchedule
                  : monthlySchedule;

                return (
                  <tr key={schedule.nurse_id}>
                    <td>{schedule.nurse_name}</td>
                    {scheduleToRender.map((status, index) => (
                      <td
                        key={index}
                        className={status === "Off" ? "off" : "work"}
                      >
                        {status}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={isWeeklyView ? daysOfWeek.length + 1 : 29}
                  style={{ textAlign: "center" }}
                >
                  No existing schedules available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {addShow && (
        <AddNurseHoliday
          show={addShow}
          onHide={() => setAddShow(false)}
        />
      )}
    </div>
  );
};

export default NurseHolidayPlanner;
