import React, { useState, useEffect } from "react";
import "./nurseroster.css"; // External CSS file
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import "simplebar-react/dist/simplebar.min.css";
import { Modal, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
const AddNurseHoliday = (props) => {
    const [nurses, setNurses] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [daysOff, setDaysOff] = useState(2);
    const [holidays, setHolidays] = useState([])
    const [shiftsPerDay, setShiftsPerDay] = useState(1); // Added state for shifts per day
    const [minNoNurses, setMinNoNurses] = useState(0); // For the min_no_nurses from API
    const [startDate, setStartDate] = useState(""); // Added state for start date
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const alert = useAlert();

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");
        const v2 = tokenString?.replace(/["]+/g, ""); // Ensure `tokenString` is handled safely

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
        };

        const fetchNurses = axios.get(`${APIURL}/api/v1/service-provider/nurse/`, { headers });
        const fetchRosterDetails = axios.get(`${APIURL}/api/v1/service-provider/roster-predetails/`, { headers });
        const fetchCreatedHolidays = axios.get(`${APIURL}/api/v1/service-provider/nurse-holidays/`, { headers });
        Promise.all([fetchNurses, fetchRosterDetails, fetchCreatedHolidays])
            .then(([nursesRes, detailsRes, holidaysRes]) => {
                if (nursesRes.status === 200) {
                    setNurses(nursesRes.data);
                }
                if (detailsRes.status === 200) {
                    setMinNoNurses(detailsRes.data.min_no_nurses); // Update min_no_nurses
                }
                if (holidaysRes.status === 200) {
                    setHolidays(holidaysRes.data.details); // Update min_no_nurses
                }
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
            });
    }, []);

    const isDateBlocked = (date) => {
        const selectedDate = new Date(date);
        return holidays.some((holiday) => {
            const holidayStart = new Date(holiday.from_date);
            const holidayEnd = new Date(holiday.to_date);
            return selectedDate >= holidayStart && selectedDate <= holidayEnd;
        });
    };

    const handleStartDateChange = (date) => {
        if (isDateBlocked(date)) {
            alert.error("The selected start date conflicts with an existing schedule.");
        } else {
            setStartDate(date);
        }
    };

    const calculateEndDate = (startDate) => {
        const start = new Date(startDate);
        const end = new Date(start);
        end.setDate(start.getDate() + 27); // Add 27 days for a total of 4 weeks (28 days)
        return end.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const generateSchedule = () => {
        if (!startDate) {
            alert.error("Please select a start date.");
            return;
        }

        // Calculate the starting day index
        const startDayIndex = new Date(startDate).getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

        // Reorganize daysOfWeek based on the start date
        const adjustedDaysOfWeek = [
            ...daysOfWeek.slice(startDayIndex),
            ...daysOfWeek.slice(0, startDayIndex),
        ];

        const minimumRequiredNurses = minNoNurses * shiftsPerDay; // Calculate minimum nurses based on shifts per day

        const newSchedule = nurses.map((nurse) => {
            const weeklySchedule = [];
            const daysOffSet = new Set();

            const startDay = Math.floor(Math.random() * (adjustedDaysOfWeek.length - daysOff + 1));
            for (let i = 0; i < daysOff; i++) {
                daysOffSet.add((startDay + i) % adjustedDaysOfWeek.length);
            }

            const weekSchedule = adjustedDaysOfWeek.map((_, index) =>
                daysOffSet.has(index) ? "Off" : "Work"
            );
            weeklySchedule.push(weekSchedule);

            return { ...nurse, weeklySchedule };
        });

        for (let dayIndex = 0; dayIndex < adjustedDaysOfWeek.length; dayIndex++) {
            let workingNurses = newSchedule.filter(
                (nurse) => nurse.weeklySchedule[0][dayIndex] === "Work"
            ).length;

            while (workingNurses < minimumRequiredNurses) {
                const randomNurse = newSchedule.find(
                    (nurse) => nurse.weeklySchedule[0][dayIndex] === "Off"
                );
                if (randomNurse) {
                    randomNurse.weeklySchedule[0][dayIndex] = "Work";
                    workingNurses++;
                }
            }
        }

        setSchedule(newSchedule);
    };

    const postSchedule = () => {
        const endDate = calculateEndDate(startDate);

        // Prepare the data to post
        const holidays = schedule.map((nurse) => {
            const offDays = daysOfWeek.filter((_, index) => nurse.weeklySchedule[0][index] === "Off");
            const workingDays = daysOfWeek.filter((_, index) => nurse.weeklySchedule[0][index] === "Work");

            return {
                nurse_id: nurse.id,
                off_days: offDays,
                working_days: workingDays,
                from_date: startDate,
                to_date: endDate,
            };
        });

        const dataToPost = { holidays };

        const tokenString = sessionStorage.getItem("usertoken");
        const v2 = tokenString?.replace(/["]+/g, "");

        axios
            .post(`${APIURL}/api/v1/service-provider/nurse-holidays/`, dataToPost, {
                headers: {
                    Authorization: "Token " + v2,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success("Schedule posted successfully!");
                } else {
                    alert.error(res.data.message);
                }
            })
            .catch((err) => {
                alert.error("Error posting schedule");
            });
    };

    const getAdjustedDaysOfWeek = (startDate) => {
        const startDayIndex = new Date(startDate).getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        return [...daysOfWeek.slice(startDayIndex), ...daysOfWeek.slice(0, startDayIndex)];
    };

    return (
        <div className="app">
            <Modal show={true} centered size="lg" onHide={props.onHide}>
                <ModalHeader closeButton>
                    <ModalTitle style={{ fontSize: '18px', margin: 0 }}><b>Add Leave Schedule</b></ModalTitle>
                </ModalHeader>
                <Modal.Body style={{ textAlign: "left", display: 'flex', flexDirection: 'column' }} >
                    <div className="controls">
                        <div className="control-group">
                            <label>Start Date:</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                            />
                        </div>
                        <div className="control-group">
                            <label>Days Off Per Week:</label>
                            <input
                                type="number"
                                value={daysOff}
                                onChange={(e) => setDaysOff(Math.max(1, Math.min(7, +e.target.value)))}
                            />
                        </div>
                        <div className="control-group">
                            <label>Shifts Per Day:</label>
                            <input
                                type="number"
                                value={shiftsPerDay}
                                onChange={(e) => setShiftsPerDay(Math.max(1, +e.target.value))}
                            />
                        </div>
                        <div className="control-group">
                            <label>Minimum Working Nurses/Shift:</label>
                            <input type="number" value={minNoNurses} disabled />
                        </div>
                        <button className="roster-button" onClick={generateSchedule}>
                            Schedule Preview
                        </button>
                        <button className="roster-button" onClick={postSchedule}>
                            Create Schedule
                        </button>
                    </div>
                    {schedule.length > 0 && (
                        <div className="schedule">
                            <h2>Week Schedule</h2>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nurse</th>
                                        {getAdjustedDaysOfWeek(startDate).map((day) => (
                                            <th key={day}>{day}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {schedule.map((nurse) => (
                                        <tr key={nurse.id}>
                                            <td>{nurse.name}</td>
                                            {nurse.weeklySchedule[0].map((status, index) => (
                                                <td key={index} className={status === "Off" ? "off" : "work"}>
                                                    {status}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div >
    );
};

export default AddNurseHoliday;
