import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import "react-table-v6/react-table.css";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from 'axios';
import { APIURL } from "../../Global";

const DepressionInventoryPopup = ({ onClose, patientId }) => {
  // Helper function to format a date as 'YYYY-MM-DD'
  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };
  // const [patientId] = useState(props.id);
  const [depressionData, setdepressionData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  // const [startDate, setStartDate] = useState(localStorage.getItem("depressionStartDate") || formatDateForInput(new Date()));
  // const [endDate, setEndDate] = useState(formatDateForInput(new Date()));
   // const [endDate, setEndDate] = useState(localStorage.getItem("depressionEndDate") || formatDateForInput(new Date()));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    try {
      // Remove IST+0530 from the date string
      const cleanDateString = dateString.replace(' IST+0530', '');
      const date = new Date(cleanDateString);
      
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return dateString; // Return original string if formatting fails
    }
  };

  

  const fetchdepressionData = async () => {
    setLoading(true);
    setError(null);
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    try {
      const response = await axios.get(`${APIURL}/api/v1/patient/assessment-results/`, {
        params: {
          patient_id: patientId,
          type: 'di',
          start_date: startDate,
          end_date: endDate,
        },
        headers: {
          Authorization: "Token " + str,
        },
      });

      console.log('Raw API Response:', response.data); // Debug log

      // Transform the data based on the API response structure
      const formattedData = response.data.data.map(item => ({
        date: formatDate(item.date_of_administration),
        rawDate: item.date_of_administration, // Keep raw date for sorting
        score: item.score,
        depression_level: item.depression_level,
        administered_by: item.administerd
      }));

      console.log('Formatted Data:', formattedData); // Debug log
      setdepressionData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };


  const handleCheck = () => {
    if (!startDate || !endDate) {
       setError('Please select both start and end dates');
       return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) {
       setError('End date must be after start date');
       return;
    }

    setError(null);
    fetchdepressionData();
 };

 // Fetch data initially when component mounts
 useEffect(() => {
    fetchdepressionData();
 }, []);

   // Update localStorage whenever startDate or endDate changes
   useEffect(() => {
    localStorage.setItem("depressionStartDate", startDate);
    localStorage.setItem("depressionEndDate", endDate);
  }, [startDate, endDate]);

  const columns = [
    {
      Header: "Date & Time",
      accessor: "date",
      style: { backgroundColor: "rgba(0,0,0,0.1)" },
      minWidth: 200,
      sortMethod: (a, b) => {
        // Sort by the raw date
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateB - dateA; // Most recent first
      }
    },
    { 
      Header: "Score", 
      accessor: "score",
      Cell: ({ value }) => value ,
      width: 100,
    },
    { 
      Header: " Level", 
      accessor: "depression_level",
      Cell: ({ value }) => (
        <div
          style={{
            maxHeight: "100px",
            overflow: "auto",
            whiteSpace: "normal",
          }}
        >
          {value}
        </div>
      ),
      minWidth: 150,
    },
    {
      Header: "Done By",
      accessor: "administered_by",
      Cell: ({ value }) => value ,
      minWidth: 150,
    }
  ];

  const chartData = {
    labels: depressionData.map(entry => entry.date),
    datasets: [
      {
        label: 'Depression Inventory Score',
        data: depressionData.map(entry => entry.score),
        fill: false,
        backgroundColor: '#9F2B68',
        borderColor: '#9F2B68',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        min: 0,
        max: 63, 
        title: {
          display: true,
          text: 'Depression Inventory Score',
          color: '#333', // Title text color
          font: {
            size: 14,
            weight: 'bold',
          }
        },
        grid: {
          display: true,
          borderColor: '#ddd', // Lighter grid lines for a cleaner look
          borderWidth: 1, // Thinner grid lines
          lineWidth: 1, // Thinner grid lines
        },
        ticks: {
          color: '#333', // Ticks color
          font: {
            size: 12,
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date & Time',
          color: '#333',
          font: {
            size: 14,
            weight: 'bold',
          }
        },
        ticks: {
          autoSkip: true, // Disable auto skipping to ensure all dates are shown
          maxRotation: 0, // No rotation, keeping them horizontal
          minRotation: 0, // Keep the dates horizontal
          padding: 10,
          color: '#333', // Ticks color
          font: {
            size: 12, // Font size for the ticks
          }
        },
        grid: {
          display: false, // Hide X-axis grid lines for a cleaner interface
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        // text: 'depression Score Trend',
        font: {
          size: 16,
          weight: 'bold',
        },
        color: '#333',
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker tooltip background
        titleColor: '#fff', // White title color for better contrast
        bodyColor: '#fff', // White body text for better contrast
        callbacks: {
          label: (context) => {
            const dataPoint = depressionData[context.dataIndex];
            return [
              `Score: ${dataPoint.score}`,
              ` Level: ${dataPoint.depression_level}`,
              // `By: ${dataPoint.administered_by}`
            ];
          }
        }
      }
    }
  };
  
  
  

  return (
    <div className="modal-overlay"
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 2000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "15px",
      paddingBottom: "15px"
    }}>
 <div className="modal-content"
      style={{
        position: "relative",
        backgroundColor: "white",
        borderRadius: "8px",
        width: "100vw",
        height: "calc(100vh - 30px)",
        maxWidth: "1200px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column"
      }}>
   
   {/* Close button in header */}
   <div className="modal-header"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 20px",
          borderBottom: "1px solid #ccc"
        }}>
     <button className="close-button"
             style={{
               cursor: "pointer",
               background: "none",
               border: "none",
               fontSize: "14px",
               fontWeight: "bold",
               color: "#ccc"
             }}
             onClick={onClose}>
       ✖
     </button>
   </div>

   
   <div style={{
     padding: "10px 20px",
   }}>
     <h5 className="title-of-tasks">Depression Inventory</h5>
   </div>

        <div className="depression-index-container" style={{ padding: "20px" }}>
          <div className="top-container" style={{
            display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginBottom: "20px", gap: "20px"
          }}>
            <div className="top-left-container" style={{
              flex: "1 1 60%", padding: "15px", borderRadius: "5px", height: "500px", 
              overflowY: 'auto' 
              //backgroundColor: "#f0f0f0"
            }}>
              <div className="date-range-container" style={{
                display: "flex", gap: "10px", padding: "10px", marginBottom: "15px", alignItems: "center",
                flexWrap: "wrap"
              }}>
                <label>From:</label>
                <input 
                  type="date" 
                  value={startDate} 
                  onChange={(e) => setStartDate(e.target.value)} 
                  style={{ marginRight: "10px" }} 
                />
                <label>To:</label>
                <input 
                  type="date" 
                  value={endDate} 
                  onChange={(e) => setEndDate(e.target.value)} 
                />
                <button 
                  onClick={handleCheck} 
                  disabled={loading}
                  className='btn btn-primary btn-col'
                
                >
                  {loading ? "Loading..." : "Check"}
                </button>
              </div>
              {error && (
                <div style={{ color: 'red', marginBottom: '10px', padding: '5px' }}>
                  {error}
                </div>
              )}
              {depressionData.length > 0 ? (
                <Line data={chartData} options={chartOptions} />
              ) : (
                <div style={{ 
                  height: '100%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  color: '#666' 
                }}>
                  No data available
                </div>
              )}
            </div>

            <div className="top-right-container" style={{
  flex: "1 1 25%",
  padding: "10px",
  borderRadius: "8px",
  overflowY: "auto",
  border: "1px solid #ccc",
  maxHeight: "350px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff"
}}>
  <h6 style={{
    fontSize: "1rem",
    color: "#333",
    marginBottom: "10px",
    borderBottom: "1px solid #eee",
    paddingBottom: "5px",
    fontWeight: "600"
  }}>Assessment Summary</h6>

  {depressionData.length > 0 ? (
    depressionData.map((entry, index) => (
      <div key={index} style={{
        border: "1px solid #e0e0e0",
        borderRadius: "6px",
        padding: "8px",
        marginBottom: "4px",
        backgroundColor: "#fafafa",
        transition: "background-color 0.3s",
      }}>
        <p style={{ marginBottom: "0", color: "#777", fontSize: "0.85rem" }}>
          {entry.administered_by} <span style={{ fontStyle: "italic" }}>administered</span>
        </p>
        <p style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>{entry.date}</p>
        <p style={{ margin: "0 0 4px", color: "#333", fontSize: "0.95rem", fontWeight: "500" }}>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Score: {entry.score}</strong>
        </p>
        <p style={{ margin: "0 0 4px", color: "#333", fontSize: "0.95rem", fontWeight: "500" }}>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}> {entry.depression_level}</strong>
        </p>
        
      </div>
    ))
  ) : (
    <div style={{ color: '#999', textAlign: 'center', fontSize: "0.95rem", paddingTop: "15px" }}>
      No data available
    </div>
  )}
</div>
          </div>

          <div className="table-container" style={{
  marginTop: "20px",
  maxHeight: "400px",
  overflowY: "auto",
  border: "1px solid #ddd",
  borderRadius: "5px",
  padding: "10px",
  maxWidth: "800px", 
  margin: "0 auto" 
}}>
  <ReactTable 
    columns={columns} 
    data={depressionData} 
    defaultPageSize={10} 
    className="-striped -highlight" 
    resizable={true}
    noDataText={loading ? "Loading..." : "No data available"}
  />
</div>

        </div>
      </div>
    </div>
  );
};

export default DepressionInventoryPopup;