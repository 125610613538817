import React, { useState, useEffect,useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { useNavigate, Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "./service.css";
import { SOCKETURL } from "../../Global";
import { Modal } from "react-bootstrap";
import AddRemark from "./AddRemark";
import { Tooltip } from 'react-tooltip';
import { OverlayTrigger, Tooltip as Tooltip1 } from 'react-bootstrap';
let ServiceHistoryDoc = () => {
  const webSocket = useRef(null);
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const [tableData,setTableData]=useState([]);
  const [hasNext, setHasNext] = useState(false)
  const [activating, setActivating] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const[page,setPage]=useState(1)
  const [filterid, setFilterId] = useState("");
  const [filtername, setFiltername] = useState("");
  const [filterstatus, setFilterStatus] = useState("");
  const [filterpatname, setFilterPatname] = useState("");
  const [filterdocname, setFilterDocname] = useState("");
  const [filternursename, setFilterNursename] = useState("");
  const [filterdate, setFilterdate] = useState("");
  const [filterqty, setFilterQty] = useState("");
  const [filterbdunit, setFilterbdunit] = useState("");
  const [totalPages, setTotalPages] = useState(1)
  let tableData2=[]
  const tableRef = useRef(null);
  let navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
  const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
  
 
  const [infoShow1, setinfoShow1] = useState(false);

  const [infoData1, setInfoData1] = useState("");

// Get current timezone dynamically
const handleInfoPopUp1 = (item) => {

  // setInfoData(item);
  const tokenString = sessionStorage.getItem('usertoken');

  let str = tokenString.replace(/["]+/g, '');



  axios.get(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${item}/?${TZ}`, {
    headers: {

      "Authorization": 'Token ' + str
    }
  })
    .then(res => {
      if (res.status === 200) {
      
        let data=res.data

      setInfoData1(data);
      setinfoShow1(true);
      
      }

 
  else {
   

  
  }



})
.catch(err => {
 

})

};


const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  
  const loadData = async  (page, per_page)  => {
    setActivating(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=${page}&per_page=${per_page}&timeZone=${currentTimezone}&
current_date=${formattedDate}&request_pid=${filterid}&name=${filtername}&patient_name=${filterpatname}&doctor_name=${filterdocname}&date=${filterdate}&quantity=${filterqty}
&nurse_name=${filternursename}&status=${filterstatus}&bedunit_code=${filterbdunit}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
    
            let data=res.data.requests
            if (data && data.length > 0) {         
             
                // const newData = data.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
                const newData1 = data.filter((item) => item.interactions !== null);
              setTableData(newData1)
              setTotalPages(res.data.total_pages)}
          }
           else {
      
          //  setErrorShow(true)
        }
      })
      .catch((err) => {
       
        // setErrorShow(true)
      });
  };

  useEffect(() => {
    loadData(page, perPage);
  }, [page, perPage]);

  useEffect(() => {
    setPage(1)
    loadData(1,perPage);
  }, [filterid,filtername,filterpatname,filterdocname,filternursename,filterdate,filterqty,filterbdunit,filterstatus]);


  const handlePageChange = (pageIndex) => {
    setPage(pageIndex + 1);
  };
 
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };


  const renderService = (cellInfo) => {

    return (

      <div > {cellInfo.column.id === "request_pid"&&tableData[cellInfo.index].pam_status&&
      <OverlayTrigger overlay={<Tooltip1 style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Updated by doctor</Tooltip1>}>
      <svg style={{marginTop:"-5px"}}  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M0 0h24v24H0z"/><path fill="orange" d="M4 11a1 1 0 0 1 .117 1.993L4 13H3a1 1 0 0 1-.117-1.993L3 11h1zm8-9a1 1 0 0 1 .993.883L13 3v1a1 1 0 0 1-1.993.117L11 4V3a1 1 0 0 1 1-1zm9 9a1 1 0 0 1 .117 1.993L21 13h-1a1 1 0 0 1-.117-1.993L20 11h1zM4.893 4.893a1 1 0 0 1 1.32-.083l.094.083l.7.7a1 1 0 0 1-1.32 1.497l-.094-.083l-.7-.7a1 1 0 0 1 0-1.414zm12.8 0a1 1 0 0 1 1.497 1.32l-.083.094l-.7.7a1 1 0 0 1-1.497-1.32l.083-.094l.7-.7zM14 18a1 1 0 0 1 1 1a3 3 0 0 1-6 0a1 1 0 0 1 .883-.993L10 18h4zM12 6a6 6 0 0 1 3.6 10.8a1 1 0 0 1-.471.192L15 17H9a1 1 0 0 1-.6-.2A6 6 0 0 1 12 6z"/></g></svg></OverlayTrigger>}
       <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {tableData[cellInfo.index][cellInfo.column.id]}{cellInfo.column.id === "request_items"?<i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp1(tableData[cellInfo.index].request_pid);
              }}
            ></i>:cellInfo.column.id === "dispatched_count"?<>\{tableData[cellInfo.index].dispensing_count}</>:""}
      </span>
     
      </div>

    );
  }
  const renderStatus = (cellInfo) => {
    return (
      <>
        <button  style={{textTransform: "capitalize" ,textAlign:"center",width: "130px",
height: "39px",
flexShrink: "0",
borderRadius: "5px",
background: tableData[cellInfo.index].status==="approved" ? "#61C65F" :"#C6725F", color: "white"}}  >
          {tableData[cellInfo.index].status}
        
         
         
        </button>
      </>
    );
  };

 
  const InfoPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <span >Medicine Details</span>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            

                  
             <div >
             <div className="row-of-features">
               <div className="col-of-features"><br/>               
                 <h6 style={{textAlign:"left"}}>Medicine Name:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.medicine_name}</span></h6>
                 {/* <h6>Strength:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.strength}</span></h6> */}
                 {/* <h6>Medicine Type:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.med_type}</span></h6> */}
                 <h6 style={{textAlign:"left"}}>Medicine type:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.med_type&&infoData1.med_type.toLowerCase().replace(/_/g, ' ')}</span></h6>
                 <h6>Form:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.form&&infoData1.form.toLowerCase().replace(/_/g, ' ')}</span></h6>
                 <h6>Frequency:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.frequency!==null?infoData1.frequency:(infoData1.date+" "+infoData1.time)}</span></h6>
                 <h6>Duration:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.duration}&nbsp;{infoData1.duration_unit} </span></h6>
                 <h6>Intake period:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake}</span></h6>
                 <h6>Quantity:<span>&nbsp;&nbsp;&nbsp;</span><span style={{textTransform:"none"}}>{infoData1.intake_count}{" "}{infoData1.intake_unit==="ml"?"mL":infoData1.intake_unit}</span></h6>
                 <h6>Intake time:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake_time&&infoData1.intake_time.toLowerCase()}</span></h6>
                 <h6>Till date:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.till_date}</span></h6>
                 <h6>Route:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.route&&infoData1.route.toLowerCase().replace(/_/g, ' ').replace(/-/g, ' ')}</span></h6>
                
               </div>
             </div>                  
        
             </div>
        
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderTableDate = (cellInfo) => {
    const datetimeString = tableData[cellInfo.index][cellInfo.column.id];
    const date = new Date(datetimeString);
    const formattedDate = date.toISOString().split('T')[0]; // Extract the date part
  
    return (
      <div>  <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {formattedDate}
        </span>
      </div>
    );
  };


  
  const columns1 = [
    // {
    //   Header: "Interactions",

      
 
    //   width: 120

    // },
    {
      Header: "Id",
      accessor: "request_pid",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      Filter: () => (
        <input
          value={filterid}
          onChange={(e) => setFilterId(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },

    {
      Header: "Medicine",
      accessor: "request_items",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      Filter: () => (
        <input
          value={filtername}
          onChange={(e) => setFiltername(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },

    {
      Header:<div>
      <span data-tooltip-id="name-tooltip1" 
        data-tooltip-content="Dispatched Count" >Dispatched Count</span>
    
      <Tooltip id="name-tooltip1" place="bottom"style={{marginTop:"53%"}}/>
    </div>,
      accessor: "dispatched_count",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      Filter: () => (
        <input
          value={filterqty}
          onChange={(e) => setFilterQty(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: renderTableDate,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      Filter: () => (
        <input
          value={filterdate}
          onChange={(e) => setFilterdate(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Patient",
      accessor: "patient",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      Filter: () => (
        <input
          value={filterpatname}
          onChange={(e) => setFilterPatname(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Doctor",
      accessor: "doctor",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},
      Filter: () => (
        <input
          value={filterdocname}
          onChange={(e) => setFilterDocname(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Nurse",
      accessor: "nurse",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},
      Filter: () => (
        <input
          value={filternursename}
          onChange={(e) => setFilterNursename(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Ward",
      accessor: "admit_details",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      Filter: () => (
        <input
          value={filterbdunit}
          onChange={(e) => setFilterbdunit(e.target.value)}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: renderStatus,
      sortable: false,
      filterable: true,
      style: { whiteSpace: "unset",color:"black" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      // width: 200,
      Filter: ({ filter, onChange }) => (
        <select
          className="serviceinput"
          onChange={(event) =>{ onChange(event.target.value); setFilterStatus(event.target.value);}}
          style={{ width: "100%" ,color: 'black'}}
          value={filterstatus}
        >
          <option value="">All</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
         
        </select>
      ),
      // Filter: ({ filter, onChange }) => (
      //   // Custom filter input component
      //   <input
      //     value={filter ? filter.value : ''}
      //     onChange={(e) => {
      //       const filterValue = e.target.value;
      //       onChange(filterValue); // Update the filter value
      //      filterDataStatus(filterValue); // Make an API request and update the filteredData state
      //     }}
      //     style={{ color: 'black' }}
      //   />
      // ),
      // width: 250,
    },
   
 
 
   
   ]

  return (
    <>
    
          
            <div  style={{marginBottom:"2%"}} className=" ">
          
             
           <ReactTable 
ref={tableRef}
data={tableData}
showPageJump={false}
columns={columns1}
resizable
manual
style={{ overflow: "wrap" }}
className="service-req-table"
defaultPageSize={perPage}
showPagination={tableData.length>0}
pages={totalPages}
page={page-1}
onPageChange={ handlePageChange}
onPageSizeChange={(newPerPage) => {
  setPerPage(newPerPage);
  setPage(1)
}}
pageSizeOptions={[20, 50, 100]}                
         /> 
           
          
      
      </div>
      {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
     {infoShow1 ? (
        <InfoPopup1
          show={infoShow1}
          onHide={() => {
            setinfoShow1(false);
          }}
        />
      ) : (
        ""
      )}

    </>
  );
};

export default ServiceHistoryDoc;
