import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Grid, Box } from "@mui/material";
import RosterTable from "./RosterTable";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
const NurseRosterTable = () => {
  const [formData, setFormData] = useState({
    nurses: 5,
    beds: 20,
    holidays: '',
    planned_leaves: "",
    shift_duration: 8,
    shifts_per_day: 3,
    overlap: 2,
    extra_nurses: 2,
  });
  const [roster, setRoster] = useState([]);
  const navigate = useNavigate();
  const alert = useAlert()
  // Map backend field names to more descriptive labels
  const fieldDetails = {
    nurses: { label: "Total Nurses", type: "number", editable: true },
    beds: { label: "Total Beds", type: "number", editable: true },
    holidays: { label: "Holiday Schedule", type: "button", editable: false },
    planned_leaves: { label: "Planned Leaves", type: "button", editable: false },
    shift_duration: { label: "Shift Details (Hours)", type: "number", editable: true },
    shifts_per_day: { label: "Shifts Per Day", type: "number", editable: true },
    overlap: { label: "Shift Overlap (Hours)", type: "number", editable: true },
    extra_nurses: { label: "Extra Nurses", type: "number", editable: true },
  };

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    const v2 = tokenString?.replace(/["]+/g, ""); // Ensure `tokenString` is handled safely

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + v2,
    };

    const fetchNurses = axios.get(`${APIURL}/api/v1/service-provider/nurse/`, { headers });
    const fetchNurseHolidays = axios.get(`${APIURL}/api/v1/service-provider/roster-predetails/`, { headers });

    Promise.all([fetchNurses, fetchNurseHolidays])
      .then(([nursesRes, holidaysRes]) => {
        if (nursesRes.status === 200) {
          // setNurses(nursesRes.data);
        }

        if (holidaysRes.status === 200) {
          // setMinNoNurses(2); // Update min_no_nurses
        }
      })
      .catch((err) => {
        alert.error("Error fetching data");
      });
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const generateRoster = () => {
    console.log("Generate Roster logic...");
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 3 }}>
        Nurse Roster Generator
      </Typography>
      <Grid container spacing={2}>
        {Object.keys(formData).map((field) => {
          const { label, type, editable } = fieldDetails[field] || {};
          return (
            <Grid item xs={6} sm={3} key={field}>
              <label style={{ display: "block", fontWeight: "bold", marginBottom: "8px" }}>
                {label}
              </label>
              {!editable && type === "button" && field === "holidays" ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => navigate("/nurse-holiday")}
                >
                  View Holidays
                </Button>
              ) : !editable && type === "button" && field === "planned_leaves" ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => navigate("/plannedleaves")}
                >
                  View Planned Leaves
                </Button>
              ) : editable ? (
                type === "textarea" ? (
                  <textarea
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    rows="4"
                    style={{ width: "100%", padding: "8px", fontSize: "14px" }}
                  />
                ) : (
                  <input
                    type={type}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      padding: "8px",
                      fontSize: "14px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                )
              ) : (
                <div
                  style={{
                    padding: "8px",
                    fontSize: "14px",
                    backgroundColor: "#f4f4f4",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                  }}
                >
                  {formData[field]}
                </div>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={generateRoster}>
          Generate Roster
        </Button>
      </Box>
      {roster.length > 0 && <RosterTable roster={roster} />}
    </Container>
  );
};

export default NurseRosterTable;

