import React,{useState,useContext } from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';

function AddDiscease(props) {
 const[data, setData]= useState({
      allergyname:'',
      allergytype:'',
      allergystage:'',
      description:''
     
        

    })
    const alert = useAlert();
    const [error,setError]=useState({
      allergyname:''
        
       });

    const[errorValidate,setErrorValidate]=useState(false)

      const handleError = () =>
  {
    setErrorValidate(false);
  }
    
      
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


    
  
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["allergyname"] || input["allergyname"]==="" ) {
      isValid = false;
      errors["allergyname"] = "Enter allergen";
    }
    if (!input["allergytype"] || input["allergytype"]==="" ) {
      isValid = false;
      errors["type"] = "Enter allergy type";
    }
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}


  const saveChanges = ()=> {
    

    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    allergy_name: data.allergyname.slice(0,1).toUpperCase() +data.allergyname.slice(1, data.allergyname.length),                            
    allergy_type : data.allergytype,
    allergy_stage: data.allergystage,                     
    description: data.description
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-allergies/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Saved successfully!');
          props.modalClose()
          setData({
            allergyname : '',
            allergytype : '',
            allergystage : '',
            description : ''

         });}
          else {
           alert.error('Allergy already exist')
         }
       })
       .catch((err) => {
         
       });
       
  
      }
}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add Allergy</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='pad-features'>
              <div  className="formField" style={{textAlign:"left"}}>
                <div style={{display:"flex"}}>
                    <label className='col-5' >*Allergen: </label>
                    
                    <input type='text' className='form-control title-flex' name='allergyname' value={data.allergyname} onChange={handleTitle} /></div>
                    {error.allergyname ? <div className="error-validation-msg error-feature-inventory col-7"style={{float:"right"}}>{error.allergyname}</div> : null}
                </div>

                <div className="formField" style={{textAlign:"left"}}> 
                  <div style={{display:"flex"}}>
                    <label className='col-5'>*Allergy Type: </label>
                    <select
                    name='allergytype'
                    className="form-control title-flex"
                  
                    value={data.allergytype}
                    onChange={handleTitle}>
                    <option value="" selected hidden  >Select</option>
                    <option value="drug" >Drug</option>  
                    <option value="food" >Food</option>  
                    <option value="environment" >Environment</option>  
                                      
                </select>
                </div>
                   
                   {error.type ? <div className="error-validation-msg error-feature-inventory col-7" style={{float:"right"}}>{error.type}</div> : null} 
                </div>
                <div className="formField" style={{display:"flex",textAlign:"left"}}>
                    <label className='col-5'>Allergy Stage: </label>
                    <input type='text' className='form-control title-flex' name='allergystage' value={data.allergystage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex",textAlign:"left"}}>
                    <label className='col-5'>Description: </label>
                    <textarea type='text' className='form-control title-flex' style={{height:"200px"}} name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                
            </div>
          </div>
          <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>   
          
        </div>
       
        </>
    )
}

export default AddDiscease
